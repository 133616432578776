import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import Icon from '@components/atoms/icon/icon';
import ProductImage from '@components/atoms/vc-images/components/product-image';
import ProductLikeButton from '@components/atoms/product-like-button/product-like-button';
import { Product } from '@interfaces/models/product';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { useAnalytics } from '@context/analytics.context';
import Link from '@components/atoms/link/link';
import { PageType } from '@interfaces/common/common';
import { getCountryNameFromCountryISOCode } from '@helpers/utils/general';
import ProductSchema from '@components/structured-schemas/product-schema';
import { ImagesService } from '@services/image-service';
import useLocalizationQuery from '@hooks/localization/use-localization';
import dynamic from 'next/dynamic';
import useActiveDealQuery from '@hooks/deal/use-active-deal-query';
import useProfileQuery from '@hooks/profile/use-profile-query';
import { useBucket } from '@context/bucket.context';
import { useRouter } from 'next/router';
import ProductCardFavActions from '@components/molecules/products/product-card-actions/product-card-fav-actions/product-card-fav-actions';
import useUser from '@hooks/user/use-user';
import styles from './product-card.module.scss';

const Badge = dynamic(() => import('@components/common/badge/badge'));

const ProductCardLocationIcon = dynamic(
  () => import('@components/molecules/products/product-card-location-icon/product-card-location-icon'),
);

const ProductCardTaxesApplyIcon = dynamic(
  () => import('@components/molecules/products/product-card-duties-apply-icon/product-card-duties-apply-icon'),
);

export type FavTileActionsABCtestVariations = 'control' | 'similar' | 'mmao';

export interface ProductCardProps {
  product: Product;
  index: number;
  customClass?: string;
  showSellerBadge?: boolean;
  showDiscountPrice?: boolean;
  showProductLocation?: boolean;
  showProductSize?: boolean;
  showProductTags?: boolean;
  showProductDirectShipping?: boolean;
  showProductExpressDelivery?: boolean;
  showTaxesApply?: boolean;
  isFavourite?: boolean;
  disableLazyLoad?: boolean;
  onClick?: (product: Product, index: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackingAlerts?: any;
  isNewProduct?: boolean;
  pageType: PageType;
  moduleType?: string;
  containerId?: string;
  productType?: string;
  forceImgLazyLoadingCb?: () => boolean;
  includeProductSchema?: boolean;
  fetchpriority?: 'high' | 'low';
  productFeedCategory?: string;
  localCountriesApplied?: boolean;
  isGoodPriceVariantEnabled?: boolean;
}

// @TODO: Currently api side returns localized badge, but FE side needs to do another round of mapping to indentify if
// the badge is expert or trusted seller to render corresponding icons. Ideally, we can ask API side to return bage type
// and let FE side do the localization and icon rendering
// phrase key: 0b84df4e0b66e9d55b22eefb55ca928f in legacy
const expertSellerList = ['Verkaufsexperte', 'Experte', 'Expert', 'Esperto', 'Experto', '專家'];

// phrase key: c0405d71f21e769c787a10b9620f11ea in legacy
const trustedSellerList = [
  'Vertrauenswürdiger Verkäufer',
  'Trusted',
  'Conformidad',
  'Recommandé',
  'Affidabile',
  'De Confianza',
  'Vertrouwd',
  'Pålitlig',
  '值得信賴',
];

const getShownBadges = (product: Product) => {
  const shouldShowExpertSellerBadge: boolean = expertSellerList.includes(product?.seller?.sellerRating?.badge);
  const shouldShowTrustedSellerBadge: boolean = trustedSellerList.includes(product?.seller?.sellerRating?.badge);
  const shouldShowOfficialStoreBadge: boolean = product?.seller && product?.seller?.isOfficialStore;

  const shouldShowBadges = shouldShowExpertSellerBadge || shouldShowTrustedSellerBadge || shouldShowOfficialStoreBadge;

  return {
    shouldShowExpertSellerBadge,
    shouldShowTrustedSellerBadge,
    shouldShowOfficialStoreBadge,
    shouldShowBadges,
  };
};

const ProductCardBadges: React.FC<Pick<ProductCardProps, 'product'>> = ({ product }) => {
  const { t } = useTranslation();

  const { shouldShowExpertSellerBadge, shouldShowTrustedSellerBadge, shouldShowOfficialStoreBadge } =
    getShownBadges(product);

  if (shouldShowOfficialStoreBadge) {
    return (
      <Badge
        type="default"
        className={clsx(
          styles.productCard__badge,
          'vc-badge vc-badge--large vc-ba  dge--sm-down vc-badge--official-store',
        )}
        badgeSize="medium"
      >
        <span className={styles.productCard__badge__text}>{t('PRODUCT.DESCRIPTION.BRAND_STORE')}</span>
      </Badge>
    );
  }
  if (shouldShowExpertSellerBadge) {
    return (
      <Badge
        type="default"
        className={clsx(styles.productCard__badge, 'vc-badge vc-badge--large vc-badge--sm-down vc-badge--expert')}
        badgeSize="medium"
      >
        <Icon
          name="badge-expert"
          className={clsx('icon', styles.productCard__badge__icon)}
        />
        <span className={styles.productCard__badge__text}>{t('PRODUCT_SNIPPET.EXPERT_SELLER')}</span>
      </Badge>
    );
  }
  if (shouldShowTrustedSellerBadge) {
    return (
      <Badge
        type="default"
        className={clsx(styles.productCard__badge, 'vc-badge vc-badge--large vc-badge--sm-down vc-badge--trusted')}
        badgeSize="medium"
      >
        <Icon
          name="badge-trusted"
          className={clsx('icon', styles.productCard__badge__icon)}
        />
        <span className={styles.productCard__badge__text}>{t('PRODUCT_SNIPPET.TRUSTED_SELLER')}</span>
      </Badge>
    );
  }
};

const ProductCard: React.FC<ProductCardProps> = (props) => {
  const {
    product,
    index,
    customClass,
    pageType = 'personalized',
    moduleType,
    showSellerBadge = false,
    showDiscountPrice = false,
    showProductLocation = false,
    showProductSize = false,
    showProductTags = false,
    showProductDirectShipping = false,
    showProductExpressDelivery = false,
    showTaxesApply = false,
    isFavourite = false,
    disableLazyLoad = false,
    trackingAlerts,
    productFeedCategory,
    isNewProduct = false,
    onClick,
    containerId,
    forceImgLazyLoadingCb,
    includeProductSchema = true,
    fetchpriority = 'low',
    localCountriesApplied,
    isGoodPriceVariantEnabled = false,
  } = props;

  const { sendAnalyticEvent } = useAnalyticEvents('product');
  const { sendEvent } = useAnalytics();
  const { t } = useTranslation();
  const { countryList } = useLocalizationQuery();
  const { activeDeal } = useActiveDealQuery();
  const { isUserProfile } = useProfileQuery();
  const router = useRouter();
  const { user } = useUser();

  const productUrl = product.path;
  const isLocalCountry: boolean = product?.flags?.includes('local-country') || localCountriesApplied;
  const isProductDirectShippable: boolean = product?.flags?.includes('direct-shipping');
  const shouldDisplaySize: boolean = showProductSize && !!product?.size?.size;
  const shouldShowProductTags: boolean = showProductTags && product?.tags?.length > 0;
  const { shouldShowBadges } = getShownBadges(product);

  const isFavouriteView = pageType === 'favourite' || pageType === 'profile-favorites';
  const { getFeature } = useBucket();
  const { option, favoritesUserSlug } = router?.query as {
    option: string; // TODO: QA testing as the API doesn't return the correct payload
    favoritesUserSlug: string;
  };
  const isUserFavourites = favoritesUserSlug?.[0] === user?.id;
  const favTileActionsABCtestVariations =
    (option as FavTileActionsABCtestVariations) ??
    (getFeature('enableFavoritesTileBottomActions', true)?.value as FavTileActionsABCtestVariations);

  const getTagName = (tag: string) => {
    switch (tag) {
      case 'weLove':
        return 'We love';
      case 'dealEligible':
        return activeDeal.active ? activeDeal.localizedName : tag;
      default:
        return tag;
    }
  };

  const tags = useMemo(() => {
    if (activeDeal?.active && product.tags?.includes('dealEligible')) {
      return ['dealEligible'];
    }
    return product.tags?.filter((x) => x !== 'dealEligible');
  }, [product.tags, activeDeal]);

  const trackProductCrossSellClick = () => {
    if (pageType === 'product_page' && moduleType === 'similar_products') {
      sendAnalyticEvent('similar_products', {
        label: `${index}`,
        property: product.id,
      });
    }
  };

  const productClickTracking = () => {
    const event = {
      type: 'event',
      payload: {
        event: 'product_click',
        product_id: String(product?.id),
        seller_id: String(product?.seller?.id ?? ''),
        product_universe: 'universeId' in product ? String(product.universeId) : '',
        product_category: product?.category?.name ?? '',
        product_sub_category: product.subcategory?.name ?? '',
        product_brand: product?.brand?.name ?? '',
        product_unitprice_ati: String((product?.price?.cents ?? 0) / 100 ?? ''),
        product_country: product?.seller?.country ?? '',
        product_position: String(index + 1 ?? ''),
        currency: product.price?.currency ?? '',
      },
    };
    const { type, payload } = event;

    switch (pageType) {
      case 'favourite':
        const feedCategoryType = isUserFavourites ? 'my' : 'member';

        sendEvent({
          type,
          payload: {
            ...payload,
            product_feed_category: `${feedCategoryType}_favourites`,
          },
        });
        return;
      case 'recommendations':
        sendEvent(event);
        return;
      case 'cms_page':
        sendEvent({
          type,
          payload: {
            ...payload,
            product_card_tag: product.tags?.join(',') ?? '',
            product_feed_category: moduleType?.replace('-', '_'),
          },
        });
        return;
      case 'alerts':
        sendEvent({
          type,
          payload: {
            ...payload,
            nb_items: trackingAlerts?.counter?.toString(),
            product_card_tag: isNewProduct ? 'new' : '',
            product_feed_category: trackingAlerts?.id ? `saved_searches_${trackingAlerts.id}` : '',
            product_unit_price_ati: ((product?.price?.cents ?? 0) / 100).toString(),
            product_position: String(product?.position),
            currency: product.price?.currency,
          },
        });
        return;
      case 'hero-pdp':
        sendEvent({
          type: 'event',
          payload: {
            ...payload,
            product_card_tag: product.tags?.join(',') ?? '',
            product_feed_category: productFeedCategory ?? '',
          },
        });
        return;
      case 'similar-products':
        sendEvent({
          type,
          payload: {
            ...payload,
            product_card_tag: product.tags?.join(',') ?? '',
            product_feed_category: 'similar_products',
          },
        });
        return;
      case 'profile-favorites':
      case 'profile-items-for-sale':
        const isProfileFavoritesPage = pageType === 'profile-favorites';
        const itemType = isUserProfile ? 'my' : 'member';

        const product_feed_category = isProfileFavoritesPage ? `${itemType}_favourites` : `${itemType}_items_for_sale`;
        sendEvent({
          type,
          payload: {
            ...payload,
            product_feed_category,
          },
        });
      default:
        return;
    }
  };

  const handleClick = async (): Promise<void> => {
    productClickTracking();
    onClick?.(product, index);
  };

  const getSEOImgPath = (path: string): string => {
    return ImagesService.improveSEOProductPathImg(path, product.path);
  };

  return (
    // TODO: Error bellow should be fixed! (We need to add keyboard listeners)
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="link"
      tabIndex={0}
      id={containerId}
      onClick={trackProductCrossSellClick}
      className={clsx(styles.productCard, customClass)}
      data-cy={`${pageType}__productCard__${product.id}`}
      data-vc-dd-action-name={`product_card__${pageType}`}
    >
      {includeProductSchema && <ProductSchema product={product} />}
      {/* Top container contains seller tags and like count */}
      <div
        className={clsx(
          styles.productCard__topContainer,
          !shouldShowBadges && styles['productCard__topContainer--onlyLikeButton'],
        )}
      >
        {/* Seller tag is shown on 'recently viewed' & catalog pages */}
        {showSellerBadge && shouldShowBadges && <ProductCardBadges product={product} />}
        {/* Like button */}
        <ProductLikeButton
          product={product}
          position={index + 1}
          showText={false}
          isFavorite={isFavourite}
          alertTracking={{
            alertTitle: trackingAlerts?.title,
            alertStatus: isNewProduct ? 'new' : '',
          }}
          pageType={pageType}
          {...{ moduleType }}
        />
      </div>

      {/* Product image */}
      <div
        className={styles.productCard__imageContainer}
        data-cy="productCard__imageContainer"
      >
        <Link
          href={productUrl}
          className={clsx(styles.productCard__image, {
            [styles.sold]: product.sold,
          })}
          onClick={handleClick}
          data-cy="productCard__image"
          disableClientSideNavigation={pageType === 'catalog'}
        >
          <ProductImage
            src={getSEOImgPath(product.pictures[0]?.path)}
            height={256}
            width={256}
            alt={product.pictures[0]?.alt ? product.pictures[0]?.alt : product.name}
            type="snippet"
            disableLazyLoad={disableLazyLoad}
            forceImgLazyLoadingCb={forceImgLazyLoadingCb}
            fetchpriority={fetchpriority}
          />
          {product.sold && <span className={styles.productCard__image__soldText}>{t('PRODUCT_SNIPPET.SOLD')}</span>}
        </Link>
      </div>
      {/* Tags */}

      {shouldShowProductTags && (
        <div className={styles.productCard__tagsContainer}>
          {tags.map((tag) => (
            <span
              style={
                tag == 'dealEligible'
                  ? { color: activeDeal.textColor, background: activeDeal.backgroundColor }
                  : { background: '#f5f6f8' }
              }
              className={styles.productCard__tag}
              key={tag}
            >
              {getTagName(tag)}
            </span>
          ))}
        </div>
      )}
      {/* Product details */}
      <div
        className={clsx(
          styles.productCard__productDetails,
          !shouldShowProductTags && styles['productCard__productDetails--noProductTags'],
        )}
        data-cy="productCard__productDetails__noProductTags"
      >
        <Link
          href={productUrl}
          onClick={handleClick}
        >
          <h2
            className={styles.productCard__productLink}
            data-cy="productCard__productLink"
          >
            {isNewProduct && <span className={styles['productCard__text--new']}>{t('ALERTS.LANDING.NEW_LABEL')}</span>}
            <span
              className={clsx(styles.productCard__text, styles['productCard__text--brand'])}
              data-cy="productCard__text__brand"
            >
              {product.brand.localizedName?.toUpperCase()}&nbsp;
            </span>
            <span
              className={clsx(styles.productCard__text, styles['productCard__text--name'])}
              data-cy="productCard__text__name"
            >
              {product.name}
            </span>
            {shouldDisplaySize && (
              <p
                className={styles['productCard__text--size']}
                data-cy="productCard__text__size"
              >
                <span className="vc-d-none">{t('PRODUCT.DESCRIPTION.DETAILS.SIZE')}</span>
                <span>
                  {product.size.size}&nbsp;
                  {product.size.localizedStandard}
                </span>
              </p>
            )}
          </h2>
        </Link>
        <div
          className={clsx(styles['productCard__text--price'], {
            [styles['productCard__text--price--discount']]: product?.regularPrice?.formatted && showDiscountPrice,
            [styles['productCard__text--price--discount--goodPriceVariant']]:
              product?.regularPrice?.formatted && showDiscountPrice && isGoodPriceVariantEnabled,
          })}
        >
          {product?.regularPrice?.formatted && (
            <span
              className={clsx({
                [styles['productCard__text--price--regularPrice']]: showDiscountPrice,
                [styles['productCard__text--price--regularPrice--goodPriceVariant']]:
                  showDiscountPrice && isGoodPriceVariantEnabled,
              })}
              data-cy="productCard__text__price__regularPrice"
            >
              {product?.regularPrice?.formatted}
            </span>
          )}
          {product?.price.formatted && (
            <span
              className={clsx(styles.productCard__text)}
              data-cy="productCard__text__price__discount"
            >
              {product?.price.formatted}
              {isGoodPriceVariantEnabled && !!product.discountPercentage && product.discountPercentage >= 20 && (
                <span className={styles.productCard__discountPercentage}>{`-${product.discountPercentage}%`}</span>
              )}
            </span>
          )}
        </div>
        {/* direct shipping eligibility and country of origin are shown on 'recently viewed' & catalog pages */}
        {/* TODO: Add direct shipping eligibility and country of origin */}
        {(showProductExpressDelivery || showProductDirectShipping) && (
          <div
            className={clsx(styles.productCard__text__bottom, 'vc-text-s')}
            data-cy="productCard__text__bottom"
          >
            {isProductDirectShippable && showProductDirectShipping && (
              <div className={styles.productCard__text__bottom__ds}>
                <Icon
                  name="handshake"
                  className={styles.productCard__text__bottom__icon}
                />
                <span className={styles.productCard__text__bottom__ds__label}>
                  {t('PRODUCT_SNIPPET.DIRECT_SHIPPING')}
                </span>
              </div>
            )}

            {product.inStock && !product.sold && showProductExpressDelivery && (
              <div className={styles.productCard__text__bottom__express}>
                <Icon
                  className={styles.productCard__text__bottom__icon}
                  name="express"
                />
                <span className={styles.productCard__text__bottom__express__label}>{t('DELIVERY_OPTS.EXPRESS')}</span>
              </div>
            )}
          </div>
        )}

        {showProductLocation && getCountryNameFromCountryISOCode(countryList, product.isoCountry) && (
          <div
            className={clsx(styles.productCard__text__bottom__location, 'vc-text-s')}
            data-cy="productCard__text__location"
          >
            {!showTaxesApply && <ProductCardLocationIcon isLocalCountry={isLocalCountry} />}
            <span className={styles.productCard__text__bottom__location__label}>
              {getCountryNameFromCountryISOCode(countryList, product.isoCountry)}
            </span>
            {showTaxesApply && product.hasDutiesApplied && <ProductCardTaxesApplyIcon />}
          </div>
        )}
      </div>
      {isFavouriteView &&
        favTileActionsABCtestVariations !== undefined &&
        favTileActionsABCtestVariations !== 'control' && (
          <ProductCardFavActions
            product={product}
            favTileActionsABCtestVariations={favTileActionsABCtestVariations}
          />
        )}
    </div>
  );
};

export default ProductCard;
