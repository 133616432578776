import React, { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import clsx from 'clsx';
import ProductService from '@services/product-service';
import { useContextSelector } from 'use-context-selector';
import { SearchContext } from '@context/search.context';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { checkFFBannedWords } from '@helpers/utils/catalog/fast-fashion-ban-utils';
import { useBucket } from '@context/bucket.context';
import { SearchBarRecentSearchesProvider } from '@context/search-bar-recent-searches.context';
import SearchButton from './components/search-button';
import styles from './search-bar.module.scss';
import SearchModal from './components/search-modal';

type SearchBarProps = {
  handleClick?: Dispatch<SetStateAction<boolean>>;
  className?: string;
};

const productSearch = async (search: string): Promise<string | null> => {
  try {
    if (!/^\d{3,10}$/.test(search)) {
      return null;
    }
    const product = await ProductService.getProduct(search);
    return product?.path || null;
  } catch (e) {
    return null;
  }
};

const handleSearch = async (searchQuery: string): Promise<void> => {
  if (!searchQuery) {
    return;
  }

  const productPath = await productSearch(searchQuery);

  if (productPath) {
    window.location.href = productPath;
    return;
  }

  window.location.href = `/search/?q=${encodeURIComponent(searchQuery)}`;
};

const SearchBar: React.FC<SearchBarProps> = (props) => {
  const { handleClick, className } = props;

  const searchQuery = useContextSelector(SearchContext, (v) => v.searchQuery);
  const isSearchHeaderOpen = useContextSelector(SearchContext, (v) => v.isSearchHeaderOpen);
  const setIsSearchHeaderOpen = useContextSelector(SearchContext, (v) => v.setIsSearchHeaderOpen);
  const { sendAnalyticEvent, updateDataLayer } = useAnalyticEvents('search_modal');
  const [showFastFashionBanner, setShowFastFashionBanner] = useState(false);
  const { features } = useBucket();
  const isFastFashionBannerEnabled = features?.enableFfBannerSearchDropdownWeb ?? false;

  useEffect(() => {
    if (showFastFashionBanner) {
      setShowFastFashionBanner(false);
    }
  }, [searchQuery]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (isFastFashionBannerEnabled && checkFFBannedWords(searchQuery)) {
      setShowFastFashionBanner(true);
      return;
    }
    sendAnalyticEvent('search_manual', {
      keyword: searchQuery,
      label: searchQuery,
    });
    updateDataLayer({
      source_category: 'search_bar',
      source_subcategory: 'manual',
    });
    await handleSearch(searchQuery);
  };

  return (
    <form
      onSubmit={onSubmit}
      className={clsx(styles.searchbar__container, className)}
    >
      {/* Button that opens the search modal on both desktop and mobile */}
      {!isSearchHeaderOpen && <SearchButton setIsSearchHeaderOpen={setIsSearchHeaderOpen} />}
      {/* Modal */}
      {isSearchHeaderOpen && (
        <SearchBarRecentSearchesProvider>
          <SearchModal
            setIsSearchHeaderOpen={setIsSearchHeaderOpen}
            handleClick={handleClick}
            showFastFashionBanner={showFastFashionBanner}
            setShowFastFashionBanner={setShowFastFashionBanner}
          />
        </SearchBarRecentSearchesProvider>
      )}
    </form>
  );
};

export default SearchBar;
