import React, { useEffect } from 'react';
import { GetServerSideProps, NextPageWithLayout } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import extractFromContext from '@helpers/utils/extract-from-context';
import { useRouter } from 'next/router';
import { isWebview as isWebviewFn } from '@helpers/routing';
import Head from '@components/atoms/head/head';
import { usePreferences } from '@context/preferences.context';
import { defaultMetas } from '@constants/metas/default';
import useUser from '@hooks/user/use-user';
import dynamic from 'next/dynamic';

const DialogWrapper = dynamic(() => import('@components/catalog/my-sizes/my-sizes/dialog-wrapper/dialog-wrapper'), {
  ssr: false,
});

export function sendAppEvent(eventName: string, someMessage: string) {
  // @ts-ignore
  window?.Android?.[eventName]();
  // @ts-ignore
  window?.webkit?.messageHandlers?.[eventName]?.postMessage(someMessage);
}

const PersonalizationPage: NextPageWithLayout = () => {
  const { isAuthenticated } = useUser();
  const { push } = useRouter();
  const { language } = usePreferences();

  const pageMetas = defaultMetas[language];

  useEffect(() => {
    if (!isAuthenticated) {
      push('/');
    }
  }, [isAuthenticated, push]);

  return (
    <>
      <Head metadata={{ ...pageMetas, title: '' }} />
      <DialogWrapper
        dialogOpen={true}
        setDialogOpen={() => {}}
        canActivateSizes={true}
        isWebview={true}
      />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale } = extractFromContext(context);
  const translations = await serverSideTranslations(locale, ['common']);
  const isWebview = isWebviewFn(context?.query);

  // /personalization page should only be used for apps webview
  // which means webview=1 param needs to be present
  if (!isWebview) {
    return { redirect: { destination: '/we-love?openPersonalization=true', permanent: false } };
  }

  return {
    props: {
      ...translations,
    },
  };
};

PersonalizationPage.Layout = [];
PersonalizationPage.PageType = 'PersonalizationPage';

export default PersonalizationPage;
