import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import Icon from '@components/atoms/icon/icon';
import AuthenticationGuard from '@components/atoms/authentication/authentication-guard/authentication-guard';
import Link from '@components/atoms/link/link';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import Button from '@components/atoms/button/button';
import { useAnalytics } from '@context/analytics.context';
import { AnalyticEvent } from '@interfaces/models/internal/analytics-event/product-event';
import useUser from '@hooks/user/use-user';
import dynamic from 'next/dynamic';
import { useBucket } from '@context/bucket.context';
import useNotificationCountQuery from '@hooks/notifications/use-notification-count-query';
import useNotificationCountQueryV2 from '@hooks/notifications-v2/use-notification-count-query-v2';
import useWindowSize from '@hooks/use-window-size';
import { FAV_DEFAULT_SORT_ORDER } from '@api/favorites';

import styles from './bottom-navigation.module.scss';

const Notifications = dynamic(() => import('@components/notifications/notifications'), {
  ssr: false,
});

const NotificationsV2 = dynamic(() => import('@components/notifications-v2/notifications-v2'), {
  ssr: false,
});

const PreviewBox = dynamic(() => import('@components/atoms/preview-box/preview-box'), {
  ssr: false,
});

const ProfileAccess = dynamic(() => import('@components/molecules/profile-access/profile-access'), {
  ssr: false,
});

const BottomNavigation: React.FC = () => {
  const { t } = useTranslation('default-layout');
  const { pathname, query } = useRouter();
  const { sendEvent } = useAnalytics();
  const { sendAnalyticEvent: sendProductAnalyticEvent } = useAnalyticEvents('navigation');
  const { user } = useUser();
  const { isWiderThanXs } = useWindowSize();

  const { isFeatureEnabled } = useBucket();
  const notificationsV2Revamp = isFeatureEnabled((f) => !!f.notificationCenterPhaseTwoScreenRevamp, true);
  const notificationCountQuery = useNotificationCountQuery();
  const notificationCountQueryV2 = useNotificationCountQueryV2();
  const notificationCountQueryToUse = !!notificationsV2Revamp ? notificationCountQueryV2 : notificationCountQuery;
  const { notificationCount: notificationCountToDisplay, resetNotificationFeedCounters } = notificationCountQueryToUse;

  const [isProfileBoxActive, setIsProfileBoxActive] = useState<boolean>(false);
  const [isNotificationsBoxActive, setIsNotificationsBoxActive] = useState<boolean>(false);

  const isOnHomepage = pathname === '/';
  const isOnSellPage = pathname === '/sell-clothes-online';
  const isOnFavoritePage = pathname.startsWith('/favorites');
  const favoritesLink = !!user?.id ? `/favorites/${user?.id}/?sortBy=${FAV_DEFAULT_SORT_ORDER}` : `/favorites/`;

  const sendAnalyticEvent = (event: AnalyticEvent) => {
    sendProductAnalyticEvent(event.value, event.data);
  };

  const onNotificationsButtonClick = (): void => {
    if (!notificationsV2Revamp) {
      resetNotificationFeedCounters();
    }
    setIsNotificationsBoxActive((prevState) => !prevState);
    sendProductAnalyticEvent('navigation_bottom_bar', {
      label: 'notifications',
      value: '4',
    });
    sendEvent({
      type: 'event',
      payload: {
        event: 'headerClick',
        button_name: 'notifications',
      },
    });
  };

  // Open notification center based on query param (TCRM-3809)
  useEffect(() => {
    if (notificationsV2Revamp && query.openNotificationCenter === 'true') {
      onNotificationsButtonClick();
    }
  }, [query.openNotificationCenter, notificationsV2Revamp]);

  const onProfileButtonClick = (): void => {
    setIsProfileBoxActive((prevState) => !prevState);
    sendProductAnalyticEvent('navigation_bottom_bar', {
      label: 'account',
      value: '5',
    });
    sendEvent({
      type: 'event',
      payload: {
        event: 'headerClick',
        button_name: 'me',
      },
    });
  };

  const handleNotificationsBoxClose = (): void => {
    setIsNotificationsBoxActive(false);
  };

  return (
    <div className={styles.bottomNavigation}>
      {/* Homepage link */}
      <Link
        href="/"
        onClick={() => sendAnalyticEvent({ value: 'navigation_bottom_bar', data: { label: 'home', value: '1' } })}
        className={styles.bottomNavigation__button}
      >
        <Icon
          className={styles.bottomNavigation__icon}
          name={isOnHomepage ? 'home-full' : 'home-line'}
        />
        <span className={clsx(styles.bottomNavigation__title, isOnHomepage && styles.bottomNavigation__titleActive)}>
          {t('HEADER.NAVIGATION.HOME')}
        </span>
      </Link>
      {/* Favorites */}
      <div className={styles.bottomNavigation__button}>
        <AuthenticationGuard shouldPreventDefault={true}>
          <Link
            href={favoritesLink}
            onClick={() =>
              sendAnalyticEvent({
                value: 'navigation_bottom_bar',
                data: { label: 'favourites', value: '2' },
              })
            }
          >
            <Icon
              className={styles.bottomNavigation__icon}
              name={isOnFavoritePage ? 'heart-full' : 'heart-line'}
            />
            <span
              className={clsx(
                styles.bottomNavigation__title,
                isOnFavoritePage && styles['bottomNavigation__title--active'],
              )}
            >
              {t('HEADER.NAVIGATION.FAVORITE_TITLE')}
            </span>
          </Link>
        </AuthenticationGuard>
      </div>

      {/* Sell items */}
      <div className={styles.bottomNavigation__button}>
        <AuthenticationGuard shouldPreventDefault={true}>
          <Link
            href="/sell-clothes-online.shtml"
            onClick={() => sendAnalyticEvent({ value: 'navigation_bottom_bar', data: { label: 'sell', value: '3' } })}
          >
            <Icon
              className={styles.bottomNavigation__icon}
              name={isOnSellPage ? 'sell-full' : 'sell-line'}
            />
            <span
              className={clsx(
                styles.bottomNavigation__title,
                isOnSellPage && styles['bottomNavigation__title--active'],
              )}
            >
              {t('HEADER.NAVIGATION.SELL_TITLE')}
            </span>
          </Link>
        </AuthenticationGuard>
      </div>

      {/* Notifications */}
      <div className={styles.bottomNavigation__item}>
        <AuthenticationGuard>
          <Button
            disableDefaultStyling
            className={styles.bottomNavigation__button}
            type="button"
            aria-labelledby="user-access-item-icon-title-notifications"
            data-cy="userAccess__item__icon__title--notif"
            aria-haspopup="true"
            aria-expanded={isNotificationsBoxActive}
            onClick={onNotificationsButtonClick}
          >
            {/* This span is used only to internationalize above button and is not displayed on page */}
            <span
              className="visuallyhidden"
              data-cy="user_access_notification_title"
              id="user-access-item-icon-title-notifications"
            >
              {t('HEADER.NAVIGATION.NOTIFICATION_TITLE')}
            </span>
            {notificationCountToDisplay > 0 && (
              <span
                className={styles.bottomNavigation__count}
                data-cy="user_access_notification_title"
              >
                {notificationCountToDisplay > 99 ? '99+' : notificationCountToDisplay}
              </span>
            )}
            <Icon
              className={styles.bottomNavigation__icon}
              name={isNotificationsBoxActive ? 'bell-full' : 'bell-line'}
              data-cy={
                isNotificationsBoxActive ? 'bottomNavigation__icon__bell-full' : 'bottomNavigation__icon__bell-line'
              }
            />

            <span
              className={styles.bottomNavigation__title}
              data-cy="bottomNavigation__title"
            >
              {t('HEADER.NAVIGATION.NOTIFICATION_TITLE')}
            </span>
          </Button>
          {isNotificationsBoxActive && isWiderThanXs && (
            <PreviewBox
              isActive={isNotificationsBoxActive}
              title={!notificationsV2Revamp ? t('HEADER.PREVIEW_BOX.NOTIFICATION_TITLE') : ''}
              closePreviewBox={handleNotificationsBoxClose}
              className={notificationsV2Revamp && isNotificationsBoxActive && 'notifications-box'}
              hideArrow={notificationsV2Revamp}
            >
              {notificationsV2Revamp ? <NotificationsV2 /> : <Notifications />}
            </PreviewBox>
          )}
        </AuthenticationGuard>
      </div>

      {/* Profile */}

      <div className={styles.bottomNavigation__item}>
        <AuthenticationGuard>
          <Button
            disableDefaultStyling
            className={styles.bottomNavigation__button}
            type="button"
            aria-label={t('HEADER.NAVIGATION.ACCOUNT_TITLE')}
            aria-haspopup="true"
            aria-expanded={isProfileBoxActive}
            onClick={onProfileButtonClick}
            data-cy="bottom-navigation-user-profile-box-open-button"
          >
            <Icon
              className={styles.bottomNavigation__icon}
              name={isProfileBoxActive ? 'user-full' : 'user-line'}
            />
            <span
              className={clsx(
                styles.bottomNavigation__title,
                isProfileBoxActive && styles['bottomNavigation__title--active'],
              )}
            >
              {t('HEADER.NAVIGATION.ACCOUNT_TITLE')}
            </span>
          </Button>
          {isProfileBoxActive && (
            <PreviewBox
              isActive={isProfileBoxActive}
              closePreviewBox={() => setIsProfileBoxActive(false)}
            >
              <ProfileAccess setIsProfileBoxActive={setIsProfileBoxActive} />
            </PreviewBox>
          )}
        </AuthenticationGuard>
      </div>
    </div>
  );
};

export default React.memo(BottomNavigation);
