import React, { FCWithChildren, useEffect, useRef, useState } from 'react';
import ProductCard, { ProductCardProps } from '@components/organisms/catalog/components/product-card/product-card';
import Button from '@components/atoms/button/button';
import clsx from 'clsx';
import { Product } from '@interfaces/models/product';
import AuthGuard from '@components/atoms/authentication/authentication-guard/authentication-guard';
import ConditionalWrapper from '@components/common/conditional-wrapper/conditional-wrapper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { useAuthentication } from '@context/authentication.context';
import { FreeMode, Mousewheel } from 'swiper';
import 'swiper/css';
import useWindowSize from '@hooks/use-window-size';
import styles from './product-slider.module.scss';

interface VisibilityTrackerProps {
  isVisible: boolean;
  index: number;
  callback?: ({ isVisible, index }: { isVisible: boolean; index: number }) => void;
}

const VisibilityTracker: FCWithChildren<VisibilityTrackerProps> = ({ isVisible, index, callback, children }) => {
  useEffect(() => {
    callback?.({ isVisible, index });
  }, [isVisible]);
  return <>{children}</>;
};

interface ProductSliderProps {
  products: Product[];
  swiperProps?: Partial<SwiperProps>;
  productCardProps?: Partial<ProductCardProps> & { pageType: ProductCardProps['pageType'] };
  onVisibilityChange?: ({ isVisible, index }: { isVisible: boolean; index: number }) => void;
  showAuthGuard?: boolean;
  includeProductSchema?: boolean;
  wrapperClassName?: string;
}

const SLIDER_BREAKPOINTS = {
  mobile: 576,
  tablet: 768,
  desktop: 1024,
  largeDesktop: 1280,
};

const ProductSlider = ({
  products,
  swiperProps,
  productCardProps,
  onVisibilityChange,
  showAuthGuard,
  includeProductSchema,
  wrapperClassName,
}: ProductSliderProps) => {
  const swiperRef = useRef(null);
  const [slideProgress, setSlideProgress] = useState(0);
  const { windowSize, isWiderThanMd } = useWindowSize();
  const windowWidth = windowSize.width;
  const { setRedirectUrl } = useAuthentication();
  const { shouldDisplayAuthDialog, setShouldDisplayAuthDialog } = useAuthentication();

  const hidePrevButton = slideProgress <= 0;
  const hideNextButton =
    slideProgress >= 1 ||
    (windowWidth >= SLIDER_BREAKPOINTS.tablet && products.length <= 3) ||
    (windowWidth >= SLIDER_BREAKPOINTS.desktop && products.length <= 4) ||
    (windowWidth >= SLIDER_BREAKPOINTS.largeDesktop && products.length <= 5);

  return (
    <div className={clsx(styles.product_slider__swiper_wrap, wrapperClassName)}>
      <Swiper
        modules={[Mousewheel, FreeMode]}
        onInit={(core) => {
          swiperRef.current = core.el;
        }}
        freeMode={{
          enabled: true,
          sticky: isWiderThanMd,
          momentumBounce: false,
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        onProgress={(e) => setSlideProgress(e.progress)}
        watchSlidesProgress={true}
        shortSwipes={false}
        draggable
        slidesPerView={2.3}
        spaceBetween={0}
        breakpoints={{
          [String(SLIDER_BREAKPOINTS.mobile)]: {
            slidesPerView: 2.42,
            spaceBetween: 0,
            centeredSlides: false,
          },
          [String(SLIDER_BREAKPOINTS.tablet)]: {
            slidesPerView: 3,
            spaceBetween: 0,
            centeredSlides: false,
          },
          [String(SLIDER_BREAKPOINTS.desktop)]: {
            slidesPerView: 4,
            spaceBetween: 0,
            centeredSlides: false,
          },
          [String(SLIDER_BREAKPOINTS.largeDesktop)]: {
            slidesPerView: 5,
            spaceBetween: 0,
            centeredSlides: false,
          },
        }}
        slidesOffsetAfter={isWiderThanMd ? 0 : 20}
        slidesOffsetBefore={isWiderThanMd ? 0 : 20}
        {...swiperProps}
      >
        {(products || []).map((product, index) => {
          return (
            <SwiperSlide
              key={product.id}
              className={styles.product_slider__swiper_slide}
            >
              {({ isVisible }) => (
                <VisibilityTracker
                  isVisible={isVisible}
                  index={index}
                  callback={onVisibilityChange}
                >
                  <ConditionalWrapper
                    condition={showAuthGuard && shouldDisplayAuthDialog}
                    wrap={(children) => (
                      <AuthGuard
                        onClick={() => {
                          setShouldDisplayAuthDialog(false);
                          setRedirectUrl({ url: product.path, mode: 'push' });
                        }}
                        shouldPreventDefault={true}
                        onAuth={() => (window.location.pathname = product.path)}
                      >
                        {children}
                      </AuthGuard>
                    )}
                  >
                    <ProductCard
                      showDiscountPrice
                      showSellerBadge
                      showProductSize
                      showProductExpressDelivery
                      showProductLocation
                      showProductDirectShipping
                      showProductTags
                      product={product}
                      index={index}
                      includeProductSchema={includeProductSchema}
                      customClass={styles.product_slider__product_card}
                      {...productCardProps}
                    />
                  </ConditionalWrapper>
                </VisibilityTracker>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Button
        data-cy="product-slider-previous-slide-button"
        disableDefaultStyling
        className={clsx(
          'vc-d-none vc-d-md-inline-block',
          styles.product_slider__swiper_button,
          styles.product_slider__prev_button,
          hidePrevButton && styles.product_slider__swiper_button__hide,
        )}
        onClick={() => swiperRef.current.swiper.slidePrev()}
      >
        {/* This span is used only to internationalize above button and is not shown on page */}
        <span hidden>click prev</span>
        <div className={clsx(styles.product_slider__prev_icon, styles.product_slider__controls_icon)} />
      </Button>
      <Button
        data-cy="product-slider-next-slide-button"
        disableDefaultStyling
        className={clsx(
          'vc-d-none vc-d-md-inline-block',
          styles.product_slider__swiper_button,
          styles.product_slider__next_button,
          hideNextButton && styles.product_slider__swiper_button__hide,
        )}
        onClick={() => swiperRef.current.swiper.slideNext()}
      >
        {/* This span is used only to internationalize above button and is not shown on page */}
        <span hidden>click next</span>
        <div className={clsx(styles.product_slider__next_icon, styles.product_slider__controls_icon)} />
      </Button>
    </div>
  );
};

export default ProductSlider;
