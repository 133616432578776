import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import useOnReveal from '@hooks/use-on-reveal';

import TextBlock from '@components/static-pages/image-section/text-block/text-block';
import VisualContainerCTA from '@components/molecules/visual-container/components/visual-container-cta';
import CMSImages from '@components/atoms/vc-images/components/cms-image';
import { EditoPageCTA, VisualContainerImage } from '@interfaces/models/editorialPage';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { ImageRatioType, ImageRatios } from '@services/image-service';
import styles from './visual-container.module.scss';

type VisualContainerProps = {
  title?: string;
  text?: string;
  ctas?: Array<EditoPageCTA>;
  image?: VisualContainerImage;
  image_link?: string;
  image_ratio?: ImageRatioType;
  image_position?: string;
  label?: string;
  _metadata: { uid: string };
  sectionIdx?: string;
  componentID: string;
} & React.HTMLProps<HTMLDivElement>;

type CtaInnerStyleType = {
  backgroundColor?: string;
  borderColor?: string;
  color?: string;
};

const imageContainerRatio = {
  '2:3': 'portrait',
  '4:3': 'paysage',
  '16:9': 'cinema',
  '9:16': 'portraitCinema',
  '21:9': 'wide',
};

const imagePositions = {
  left: 'imgLeft',
  above: 'imgAbove',
  below: 'imgBelow',
};
const defaultImageRaito = '2:3';

const VisualContainer: React.FC<VisualContainerProps> = (props) => {
  const {
    title,
    text,
    image,
    ctas,
    image_ratio: imgRatio,
    image_link: imgLink,
    image_position: imgPosition,
    label,
    sectionIdx,
    componentID,
    _metadata,
  } = props;
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { filename: imgXName, url: imgPath, title: imgTitle } = image || {};

  const visualImageRatio = ImageRatios.indexOf(imgRatio) ? imgRatio : defaultImageRaito;
  const contentTypeUid = componentID ? componentID.split('-')[0] : '';

  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        property: `${_metadata?.uid}_${label}`,
        action: contentTypeUid,
        label: sectionIdx || '',
      });
    }, [label, sectionIdx]),
    0.5,
    false,
  );

  const trackVisualContainerClick = (e) => {
    sendAnalyticEvent('cms_content_click', {
      category: 'cms_content_click',
      property: label,
      label: sectionIdx || '',
      action: contentTypeUid,
    });

    e.stopPropagation();
  };

  const containerClsx = useMemo(() => {
    const clsx = ['innerContainer', styles.visualcontainer];

    if (imagePositions[imgPosition]) {
      clsx.push(styles[imagePositions[imgPosition]]);
    }

    return clsx.join(' ');
  }, [imgPosition]);

  const imgContainerClsx = useMemo(() => {
    const clsx = [styles.visualcontainer_img_module];

    clsx.push(styles[imageContainerRatio[visualImageRatio] || 'paysage']);

    return clsx.join(' ');
  }, [visualImageRatio]);

  const visualContainerCTAs = useMemo(() => {
    if (ctas?.length < 1) {
      return null;
    }

    return ctas.map(({ _metadata, cta_s_background_color: ctaBgColor, cta_s_text_color: ctaTextColor, cta }) => {
      let ctaInnerStyle: CtaInnerStyleType = {};

      if (ctaBgColor) {
        ctaInnerStyle = {
          backgroundColor: ctaBgColor,
          borderColor: ctaBgColor,
        };
      }

      if (ctaTextColor) {
        ctaInnerStyle.color = ctaTextColor;
      }

      const key = `vc-cta-${_metadata?.uid}`;

      return (
        <VisualContainerCTA
          key={key}
          innerStyle={ctaInnerStyle}
          cta={cta}
          tracking={{
            contentTypeUid,
            sectionIdx,
            label,
          }}
        />
      );
    });
  }, [ctas]);

  return (
    <section ref={containerRef}>
      <div className={clsx(containerClsx)}>
        <div className={clsx(imgContainerClsx)}>
          <a
            href={imgLink}
            onClick={trackVisualContainerClick}
            className={styles.visualcontainer_a}
            itemProp="url"
          >
            <CMSImages
              src={imgPath}
              type="edito"
              disableLazyLoad
              alt={imgTitle}
              data-src={imgXName}
              width={500}
              height={500}
              wrapperProps={{ className: styles.visualcontainer_img_container }}
            />
          </a>
        </div>
        {text && (
          <div className={styles.visualcontainer_text_module}>
            <div className={styles.visualcontainer_text_container}>
              <TextBlock {...{ title }}>{text}</TextBlock>

              <div className={styles.visualcontainer_cta_module}>{visualContainerCTAs}</div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default VisualContainer;
