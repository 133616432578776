import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { UserContext } from '@context/user.context';
import { useContextSelector } from 'use-context-selector';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import useOnReveal from '@hooks/use-on-reveal';
import Button from '@components/atoms/button/button';
import { ContentStackPageNames } from '@enums/contentStack';
import CatalogList from '@components/molecules/catalog-list/catalog-list';
import useProductCatalogProducts from '@hooks/catalog-product/use-product-catalog-products';
import ProductCardSkeleton from '@components/organisms/catalog/components/skeleton-components/product-card-skeleton/product-card-skeleton';
import { useBucket } from '@context/bucket.context';
import useUser from '@hooks/user/use-user';
import DialogWrapper from '@components/catalog/my-sizes/my-sizes/dialog-wrapper/dialog-wrapper';
import ProductBlock from '../product-block/product-block';
import styles from './product-catalog.module.scss';

type ProductCatalogProps = {
  displayTitle: string;
  url: string;
  cta: {
    title: string;
    href: string;
  };
  ctaTextColor?: string;
  ctaBackgroundColor?: string;
  productType?: string;
  isPersonalizable: boolean;
  productFeedIndex?: 'ng-products-slave-recent' | 'ng-product-master';
  title: string;
  uuid: string;
  sectionIdx: string;
  maxProductCount?: number;
  pageType?: string;
  arrangement?: string;
  contentTypeUid?: string;
  campaign_id?: string;
};

const ProductCatalog: React.FC<ProductCatalogProps> = (props): React.JSX.Element => {
  const {
    displayTitle: title,
    cta,
    isPersonalizable,
    ctaTextColor,
    ctaBackgroundColor: ctaBGColor,
    productType,
    uuid: sectionId,
    productFeedIndex,
    sectionIdx,
    maxProductCount,
    pageType,
    arrangement,
    contentTypeUid,
    campaign_id,
  } = props;

  const { sendAnalyticEvent, updateDataLayer } = useAnalyticEvents('my_preferences');
  const { t } = useTranslation();
  const userSizes = useContextSelector(UserContext, (v) => v.userSizes);
  const { isAuthenticated } = useUser();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const isPersonalized = isAuthenticated && isPersonalizable;
  const isSliderHorizontal = arrangement === 'horizontal_slider';
  const { isFeatureEnabled } = useBucket();

  const isLayoutFeatureFlagEnabled = useMemo(() => {
    switch (pageType) {
      case ContentStackPageNames.CUSTOM_PAGE:
        return isFeatureEnabled((f) => f.sliderLandingEditoPage, true);
      case ContentStackPageNames.EDITORIAL_PAGE:
        return isFeatureEnabled((f) => f.sliderLandingEditoPage, true);
      default:
        return isFeatureEnabled((f) => f.sliderHomePage, true);
    }
  }, [pageType, isFeatureEnabled]);

  const ctaStyle = Object.assign({}, ctaBGColor && { background: ctaBGColor }, ctaTextColor && { color: ctaTextColor });
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        action: 'product',
        category: 'cms_content_view',
        label: sectionIdx,
        property: `${sectionId}_${productType}`,
      });
    }, [productType, sectionId, sectionIdx]),
    0.25,
    false,
  );

  const updataDataLayerOnClick = () => {
    const source_subcategory = contentTypeUid ?? (campaign_id ? `campaign_id_${campaign_id}` : '');
    updateDataLayer({
      source_category: 'cms_block',
      source_subcategory,
    });
  };

  const navigateToUrl = useCallback(() => {
    updataDataLayerOnClick();
    window.location.href = cta?.href;
  }, [cta?.href]);

  const bottomSection = useMemo(
    () => (
      <div className={styles.product__block__bottom}>
        <div className="innerContainer">
          <Button
            variant="primary"
            size="medium"
            fullWidth
            notFullWidthQuery="md-up"
            style={ctaStyle}
            onClick={navigateToUrl}
          >
            {cta?.title}
          </Button>
        </div>
      </div>
    ),
    [cta, ctaStyle, navigateToUrl],
  );

  const doesUserHaveSizes = useMemo<boolean>(() => {
    return userSizes?.universe?.ids.length > 0;
  }, [userSizes]);

  const mySizes = useMemo(() => {
    const link = (
      <span
        aria-hidden
        className={clsx(styles.catalogPage__catalog__subtitle__button, 'vc-text-m')}
        onClick={setDialogOpen.bind(null, true)}
        onKeyDown={setDialogOpen.bind(null, true)}
        key="mysizes-button"
      >
        {doesUserHaveSizes ? t('HOMEPAGE.MYSIZES_BUTTON') : t('HOMEPAGE.MYSIZES_BUTTON_NOPERSO')}
      </span>
    );
    const text = (
      <span
        className="vc-text-m"
        key="mysizes-title"
      >
        {doesUserHaveSizes ? t('HOMEPAGE.MYSIZES_TITLE') : t('HOMEPAGE.MYSIZES_TITLE_NOPERSO')}
      </span>
    );

    return (
      <div className={clsx(styles.catalogPage__catalog__subtitle, 'innerContainer')}>
        {doesUserHaveSizes ? [text, link] : [link, text]}
      </div>
    );
  }, [t, doesUserHaveSizes]);

  const { products, isLoading } = useProductCatalogProducts({
    cta,
    isPersonalized,
    productFeedIndex,
    productType,
    maxProductCount,
    isSliderHorizontal,
  });

  return (
    <>
      {isLayoutFeatureFlagEnabled && isSliderHorizontal ? (
        <section
          ref={containerRef}
          className={styles.product__block}
          data-cy={`product_block_${productType || 'container'}`}
        >
          <div className={styles.catalogPage}>
            <div className={styles.catalogPage__catalog}>
              {isLoading ? (
                <ul className={styles.catalog__loadingContainer}>
                  {Array.from(Array(4).keys()).map((key) => (
                    <li key={key}>
                      <ProductCardSkeleton />
                    </li>
                  ))}
                </ul>
              ) : (
                <div className={styles.catalog__resultContainer}>
                  <ProductBlock
                    products={products}
                    title={title}
                    productType={productType}
                    showAuthGuard={true}
                    cta={{ ...cta, sendClickAnalytics: updataDataLayerOnClick }}
                    subTitle={isPersonalized && mySizes}
                    productCardProps={{
                      moduleType: productType,
                      productType: productType,
                      pageType: 'cms_page',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {isPersonalized && (
            <DialogWrapper
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              canActivateSizes={true}
            />
          )}
        </section>
      ) : (
        <section
          ref={containerRef}
          className={styles.product__block}
          data-cy={`product_block_${productType || 'container'}`}
        >
          <div className="innerContainer">
            <h2
              className={clsx(
                styles.product__block__title,
                'vc-title-m',
                isPersonalized && styles['product__block__title--hasSubtitle'],
              )}
            >
              {title}
            </h2>
          </div>
          <div className={styles.catalogPage}>
            <div className={styles.catalogPage__catalog}>
              {isPersonalized && mySizes}
              <div className={clsx(styles.catalog, 'innerContainer')}>
                <CatalogList
                  containerClsx={styles.catalog__resultContainer}
                  products={products}
                  isLoading={isLoading}
                  {...{ productType }}
                />
              </div>
            </div>
          </div>
          {!isLoading && cta?.href ? bottomSection : null}
          {isPersonalized && (
            <DialogWrapper
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              canActivateSizes={true}
            />
          )}
        </section>
      )}
    </>
  );
};

export default ProductCatalog;
